import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/LayoutComponent"
import Seo from "../components/SeoComponent"
import "../assets/larq.css"

const RecomendLarq = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "preporuka" })}
        canonicalUrl={"recomend-larq"}
      />
      <div className="larq">
        <StaticImage
          className="larq-img"
          src="../images/recomend/LARQ_RECOMMONDATION.jpg"
          alt="Larq recomendation"
          layout="constrained"
          placeholder="blurred"
        />
      </div>
    </Layout>
  )
}

export default RecomendLarq
